.heroBody {
  position: relative;
  background-image: url(/assets/img/hero.svg);
  background-size: 40%;
  background-position: 90% 50%;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    background-image: none;
  }
}

.featureImage {
  max-width: 60%;
  margin: 0 auto;
}

.demoContainer {
  background-image: linear-gradient(
    141deg,
    #fffa70 0%,
    #ffdd57 50%,
    #ffaf24 100%
  );
}
